<template>
  <div>
    <!--  
    <h1 class="title is-5 k is-hidden-dektop">Creator Dashboard</h1>
   {{ hasBots }}
     -->

    <nav class="btTabs">
      <router-link v-for="tab in tabs" :to="tab.link" class="button is-light spaced-button" :key="tab.label">
        <span>{{ tab.label }}</span>
      </router-link>
    </nav>
    <br />
  </div>
</template>

<script>
export default {
  computed: {
    // Using Vuex getter (assuming you have a getter named 'hasBots')
    hasBots() {
      return this.$store.main.getters.hasPages;
    },
    tabs() {
      // Define tabs with visibility checks inside
      const tabsArray = [
        { link: "/bots?ref=tabs", label: "Featured", visible: true },
        { link: "/bots/me", label: "My Bots", visible: this.hasBots }, // Visibility depends on hasBots

        { link: "/conversational", label: "Conversational", visible: true },
        { link: "/images", label: "Visual", visible: true },
        { link: "/social", label: "Social", visible: true },
        { link: "/editorial", label: "Editorial", visible: true },
      ];

      // Filter out tabs where visible is false
      return tabsArray.filter((tab) => tab.visible);
    },
  },
};
</script>

<style scoped>
.spaced-button {
  margin-right: 10px;
}

/* bt tabs*/
.btTabs {
  margin-top: 20px;
}
.btTabs .button {
  display: inline-block;
  margin-right: 5px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  border-radius: 12px;
  color: #777;
}
.btTabs .button .icon {
  margin-left: 30px;
}

.btTabs .button:not(:hover) {
  background: transparent;
}

.btTabs .router-link-active {
  background: #eaeaea !important;
  color: #000 !important;
}
@media screen and (max-width: 940px) {
  .btTabs .button {
    margin-right: 3px;
    font-size: 15px;
    border-radius: 9px;
    padding-left: 7px;
    padding-right: 7px;
  }
}
</style>
