var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:""},[_c('titleBar',{attrs:{"title":" ","back":false,"showBackArrowDesktop":true,"subtitle":"","centered":false,"theme":"white","showSignup":false,"containerClass":"donotuse","showDesktop":true,"showAfterScroll":0,"scrollTopOnClick":true,"inline":false,"actionButtonStyle":"small","actionButtonRoundBg":"transparent","iconPack":"far","isHome":false,"actions":[
      {
        icon: 'search',
        to: '/explore',
        hidden: false,
        hiddenTablet: false,
      },
      {
        icon: 'bell',
        to: '/notifications',
        hidden: false,
        hiddenTablet: false,
      },
      /*  {
        icon: 'comment',
        to: '/messages',
        hidden: false,
        hiddenTablet: false,
      },*/
    ]}}),_vm._v(" "),_c('div',{staticClass:"section"},[_c('botsTabs',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_vm._v(" "),_c('exploreTabs')],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"section"},[_c('div',{staticClass:"container",staticStyle:{"max-width":"1000px"}})]),_vm._v(" "),_c('FeaturedBots',{attrs:{"blocks":_vm.blocks}}),_vm._v(" "),_c('div',{staticClass:"section"},[(!_vm.loadingProj)?_c('div',{staticClass:"container home"}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }